// react core
import React from 'react';
import { useState, useEffect } from 'react';
import { company } from 'api/account';
import PureModal from 'react-pure-modal';

// app componets
import AjaxSpinner from 'components/commons/ajax_spinner';
import CompanyCard from 'components/commons/company_card';
import AccountsCard from 'components/commons/accounts_card';

// api
import { user_profile } from 'api/account';

export default function MyCompanyPage()
{
    const [data, set_data] = useState();
    const [error, set_error] = useState(false);
    const [is_loading, set_is_loading] = useState(false);
    const [all_users, set_all_users] = useState([]);
    const [need_refresh, set_need_refresh] = useState(0);
    const [user_status_changed, set_user_status_changed] = useState(false);
    const [user_status_error, set_user_status_error] = useState(false);
    const [user_last_changed, set_user_last_changed] = useState();
    const [user_last_status, set_user_last_status] = useState();


    useEffect(() =>
    {
        let mounted = true;
        const fetchData = async () =>
        {
            const response = await company.get();
            if (response.ok)
            {
                const data = await response.json();
                if (mounted)
                {
                    set_is_loading(false);
                    if(data.status === 'ok')
                    {
                        window.$DATA = data.data;
                        set_data(data.data);
                        set_all_users(data.data.all_users);
                    }
                    else
                    {
                        set_error(true);
                    }
                }
            }
            else
            {
                set_error(true);
                set_is_loading(false);
            }
        }
        set_is_loading(true);
        // call the function
        fetchData().catch(console.error);
        return () => mounted = false; // cleanup function
    }, [need_refresh]);

    const on_activate = (evt, userid) =>
    {
        evt.stopPropagation();
        const send_data = async () =>
        {
            const response = await user_profile.activate(userid)
            set_is_loading(false);
            const data = await response.json();
            set_user_last_changed(data.fullname);
            console.log("data => " + JSON.stringify(data));
            if (response.ok)
            {
                set_user_status_changed(true);
                set_user_last_status('active');
            }
            else {
                set_user_status_error(true);
            }
            set_need_refresh(need_refresh+1);
        }
        set_is_loading(true);
        // call the function
        send_data().catch(console.error);

    };

    const on_deactivate = (evt, userid) =>
    {
        evt.stopPropagation();
        const send_data = async () =>
        {
            const response = await user_profile.deactivate(userid)
            set_is_loading(false);
            const data = await response.json();
            console.log("data => " + JSON.stringify(data))
            set_user_last_changed(data.fullname);
            if (response.ok)
            {
                set_user_status_changed(true);
                set_user_last_status('inactive');
            }
            else {
                set_user_status_error(true);
            }
            set_need_refresh(need_refresh+1);
        }
        set_is_loading(true);
        // call the function
        send_data().catch(console.error);

    };

    const classess = is_loading ? 'loading' : '';
    if(error === true) return (
        <div id="content" className='errors'>
            Unable to reach server
        </div>
    );
    return (<>
        <div id="content" className={classess}>
            <AjaxSpinner is_loading={is_loading} />
            <h2>My Company</h2>

            <CompanyCard
                is_manager={data?.is_manager}
                company={data}
            />

            <AccountsCard
                is_manager={data?.is_manager}
                onActivate={on_activate}
                onDeactivate={on_deactivate}
                data={all_users}
            />
        </div>

            {/* STATUS CHANGED */}
            <PureModal
                isOpen={user_status_changed}
                closeButton="OK"
                width="80%"
                closeButtonPosition="bottom"
                className="modal-status-changed"
                onClose={() => {
                    set_user_status_changed(false);
                    return true;
                }}
            >
                <div className="modal-message">
                    <div>
                        <p><strong>Done!</strong> </p>
                        <p>The status of <strong>{user_last_changed}</strong> has been changed</p>
                        {user_last_status == 'active' && (
                            <p>The user can now access the CCH<sup>&reg;</sup> Tagetik Community</p>
                        )}
                        {user_last_status == 'inactive' && (
                            <p>The user can no more access the CCH<sup>&reg;</sup> Tagetik Community</p>
                        )}

                    </div>
                </div>
            </PureModal>

            {/* STATUS ERROR */}
            <PureModal
                isOpen={user_status_error}
                closeButton="OK"
                width="80%"
                closeButtonPosition="bottom"
                className="modal-status-error"
                onClose={() => {
                    set_user_status_error(false);
                    return true;
                }}
            >
                <div className="modal-message">
                    <div>
                        <p>The status of <strong>{user_last_changed}</strong> status can't be changed</p>
                        <p>Check your quota or contact <a href="mailto:tgk-dl-community@wolterskluwer.com">tgk-dl-community@wolterskluwer.com</a>{" "} for assistance</p>
                    </div>
                </div>
            </PureModal>

            </>);
}
