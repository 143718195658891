// react core
import React, { useEffect } from 'react';

const add_bodyclass = className => document.body.classList.add(className);
const remove_body_class = className => document.body.classList.remove(className);

export default function BodyClasser({className})
{

    useEffect(
    () =>
        {
            if(!document.body.classList.contains(className))
            {
                add_bodyclass(className);
            }
            // Clean up
            return () =>
            {
                remove_body_class(className);
            };
        },
        [className]
    );
    return <></>
};

