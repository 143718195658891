import React, { useEffect } from 'react';
import { useState } from 'react';
import TableFooter from './table_footer';
import useTable from 'api/useTable';


function ButtonActive({ val, onActivate, onDeactivate, userid })
{
    if (val === true)
        return (
            <div className="switch-wrapper">
                <label className="switch" title="Click to deactivate">
                    <input type="checkbox" checked="checked" onChange={(evt) => onDeactivate(evt, userid)} />
                    <span className="slider"></span>
                </label>
                <span>Active</span>
            </div>
        );
    else
        return (
            <div className="switch-wrapper">
                <label className="switch" title="Click to activate">
                    <input type="checkbox" onChange={(evt) => onActivate(evt, userid)} />
                    <span className="slider"></span>
                </label>
                <span>Inactive</span>
            </div>
        );
}


export default function AccountsCard({ is_manager, onActivate, onDeactivate, data, rowsPerPage = 15 })
{

    const [page, setPage] = useState(1);
    const [_data, set_data] = useState([]);
    const { slice, range } = useTable(_data, page, rowsPerPage);
    const [text, set_text] = useState('');
    const [option, select_option] = useState('all');

    useEffect(() =>
    {
        setPage(1);
        set_data(data);
    }, [data]);

    useEffect(() =>
    {
        if (text.length >= 3)
        {
            const result = data.filter(e => e.name.toLowerCase().includes(text.toLowerCase()));
            set_data(result);
        }
        if (text === '')
        {
            set_data(data);
        }
        setPage(1);
    }, [text]);


    const options = [
        { value: 'all', label: 'Status: All' },
        { value: 'active', label: 'Status: Active' },
        { value: 'inactive', label: 'Status: Inactive' }
    ];

    const option_change = (value) =>
    {
        console.log("change " + value);
        select_option(value);
        let tmp = JSON.parse(JSON.stringify(data));
        let result = null;
        if(value === 'active') result = tmp.filter(item => item.active === true);
        if(value === 'inactive') result = tmp.filter(item => item.active === false);
        if(value === 'all') result = tmp;

        set_data(result);
    };

    if(is_manager === true) return (
        <div className="company-accounts">
            <h3>Manage Company Accounts</h3>

            <table className="paginated table table-bordered">
                <thead>
                    <tr>
                        <th>
                            <select value={option} onChange={(e) => option_change(e.target.value)}>
                                {options.map((obj, iter) => {
                                    const k = `o_${iter}`;
                                    return (
                                        <option key={k} value={obj.value}>
                                            {obj.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </th>
                        <th>
                            <input
                                type="text"
                                value={text}
                                onChange={(e) => set_text(e.target.value)}
                                placeholder="Filter table by full name"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {slice.map((val, key) => {
                        return (
                            <tr key={key}>
                                <td>
                                    {val.is_valid && (
                                        <ButtonActive
                                            onActivate={onActivate}
                                            onDeactivate={onDeactivate}
                                            userid={val.userid}
                                            val={val.active}
                                        />
                                    )}
                                    {!val.is_valid && <em>profile not complete</em>}
                                </td>
                                <td>
                                    <a href={"mailto:" + val.userid}>
                                        <i className="fa-solid fa-envelope"></i> {val.name}
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
        </div>
    );
    return <></>
}

AccountsCard.defaultProps =
{
    data: []
}