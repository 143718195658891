import React, { useEffect } from "react";

const TableFooter = ({ range, setPage, page, slice }) =>
{
    useEffect(() =>
    {
        if (slice.length < 1 && page !== 1)
        {
            setPage(page - 1);
        }
    }, [slice, page, setPage]);

    const last_page = range[range.length-1];
    const first_active = page !== 1;
    const last_active = page !== last_page;

    return (
        <>
            <div className="tableFooter">
                <div>
                    {first_active && (
                        <button className="button button-small" key="prev" onClick={() => setPage(page - 1)}>
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                    )}
                </div>
                <div>
                    Page {page} of {last_page}
                </div>
                <div>
                    {last_active && (
                        <button className="button button-small" key="next" onClick={() => setPage(page + 1)}>
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};


export default TableFooter;