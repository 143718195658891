// core
import React from 'react';
import
{
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import SiteRoot from 'pages/master';
// route conf
import conf from 'conf/config';

// components
import AjaxSpinner from 'components/commons/ajax_spinner';
import NotFound from 'components/commons/404';
// state
// ?

// pages
import MyProfilePage from 'pages/my_profile';
import ChangeOwnPassword from 'pages/change_pass';
import NotificationPage from 'pages/notifications';
import MyCompanyPage from 'pages/my_company';
import SendEndRedirect from 'pages/send_and_redirect';

export default function Main(props)
{
    const router = createBrowserRouter([
        {
            path: conf.route.base,
            element: <SiteRoot />,
            errorElement: <NotFound />,
            children: [
                {
                    path: conf.route.my_profile,
                    element: <MyProfilePage user={props.user}/>
                },
                {
                    path: conf.route.my_company,
                    element: <MyCompanyPage user={props.user}/>
                },
                {
                    path: conf.route.change_password,
                    element: <ChangeOwnPassword user={props.user}/>
                },
                {
                    path: conf.route.my_notifications,
                    element: <NotificationPage user={props.user}/>
                },
                {
                    path: conf.route.seen_and_redirect,
                    element: <SendEndRedirect user={props.user}/>
                },
                {
                    path: '*',
                    element: <NotFound />
                }
            ]
        }
    ]);
    return (
        <main className="container">
            <RouterProvider router={router}
                errorElement={<NotFound/>}
                fallbackElement={<AjaxSpinner />}
            />
        </main>
    );
};