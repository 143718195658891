// note:
// fontawesome don't trigger icon change see:
// https://stackoverflow.com/questions/49899245/setstate-not-updating-font-awesome-icon

// react core
import React from 'react';
import { useState } from 'react';

export default function ShowAndHidePassword(props)
{
    const [_type, set_type] = useState("password");

    const pass_toggler = () =>
    {
        if (_type === "password")
        {
            set_type("text");
            if(props.autohide && props.autohide === "true")
            {
                setTimeout(() =>
                {
                    set_type("password");
                }, 20000);
            }
            return;
        }
        set_type("password");
    };




    return (
        <div className={`password-input password-input--${_type}`}>
            <input type={_type} {...props}/>
            { (_type === "password") &&
                <div key="one" title="show password" onClick={pass_toggler} className="password-input-toggle">
                    <i className="fa-solid fa-eye"></i>
                </div>
            }
            { (_type !== "password") &&
                <div key="two" title="hide password" onClick={pass_toggler} className="password-input-toggle">
                    <i className="fa-solid fa-eye-slash"></i>
                </div>
            }
        </div>

    )
}
