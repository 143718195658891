// note, this is the priority:
// npm start: .env.development.local, .env.local, .env.development, .env
// npm run build: .env.production.local, .env.local, .env.production, .env
// npm test: .env.test.local, .env.test, .env (note .env.local is missing)
// see more on https://create-react-app.dev/docs/adding-custom-environment-variables

const plone_site_root = process.env.REACT_APP_plone_site;
const plone_endpoint = process.env.REACT_APP_server;

const _conf =
{
    came_from: process.env.REACT_APP_came_from,
    login_url: process.env.REACT_APP_login_url,
    alert_base_page: process.env.REACT_APP_alert_base_page,
    endpoints:
    {
        info: `${plone_endpoint}/@accounts-info`,
        get_user_profile: `${plone_endpoint}/@accounts-get_user_profile`,
        customer_profile: `${plone_endpoint}/@accounts-get_customer_profile`,
        lost_password: `${plone_endpoint}/@users/{USERID}/reset-password`,
        set_user_profile: `${plone_endpoint}/@accounts-set_user_profile`,
        get_my_alerts: `${plone_endpoint}/@accounts-my-alerts`,
        see_my_alert: `${plone_endpoint}/@accounts-see-alert`,
        activate_user: `${plone_endpoint}/@accounts-activate_user_profile`,
        deactivate_user: `${plone_endpoint}/@accounts-deactivate_user_profile`,
    },
    plone_site: plone_site_root,
    server:
    {
    },
    resources:
    {
        header: process.env.REACT_APP_resources_header,
        footer: process.env.REACT_APP_resources_footer
    },
    route:
    {
        base: '/accounts',
        my_profile: 'my-profile',
        my_company: 'my-company',
        change_password: 'change-password',
        my_notifications: 'my-notifications',
        seen_and_redirect: 'seen_and_redirect'
    },
    DEBUG: (process.env.REACT_APP_DEBUG==='TRUE')
};

window.$CONF = _conf;
export default _conf;
