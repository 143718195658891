// see https://plonerestapi.readthedocs.io/en/latest/endpoints/users.html?highlight=reset#reset-user-password
// react core
import React from 'react';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import PasswordStrengthBar from 'react-password-strength-bar';
import PureModal from 'react-pure-modal';

// components
import AjaxSpinner from 'components/commons/ajax_spinner';
import ShowAndHidePassword from 'components/commons/show_hide_pass';

// libs
import { AUTH } from 'api/account';

function ASCIIValid(_str, ascii_min=33, ascii_max=126)
{
    if(!(ascii_min >= 0 && ascii_min <=255)) throw new Error('Parameter is not in a valid range!');
    if(!(ascii_min >= 0 && ascii_min <=255)) throw new Error('Parameter is not in a valid range!');
    for (let i = 0; i < _str.length; i++)
    {
        const _char = _str.charCodeAt(i);
        if (_char > ascii_max || _char < ascii_min)
        {
            return false;
        }
    }
    return true;
}

function SamePassChecker({ password, password_check })
{
    if (password === password_check) return (<></>)
    return (
        <div className="password-repeat-error">
            Passwords don't match
        </div>
    )
}

function OldPassChecker({ is_wrong })
{
    if (is_wrong)
    {
        return (<div>Wrong old password</div>)
    } else
    {
        return (<></>)
    }

}

function ValidPassChecker({ password })
{
    if (password.length < 1) return <div>&nbsp;</div>
    if (ASCIIValid(password)) return <div>&nbsp;</div>
    return (
        <div className='passwordchecker'>
            Valid characters are numbers, letters and symbols .!@#$%^&*
        </div>
    )
}

function ButtonSender({ on_click, password, password_check, old_password, score })
{
    if (false)
    {
        // XXX DEBUG
        console.log("check " + (password === password_check).toString());
        console.log("equal " + (password.length >= 6).toString());
        console.log("score " + score);
        console.log("old_password " + old_password);
        console.log("len " + password.length);
    }
    if (password === password_check && password.length >= 6 && score >= 2 && old_password && ASCIIValid(password))
    {
        return (<>
            <button onClick={on_click} className="button button-primary">Change Password</button>
        </>)
    }
    else
    {
        return (
            <>
                <button disabled className="button button-primary">Change Password</button>
            </>
        )
    }
}

export default function ChangeOwnPassword({ user })
{
    console.log(JSON.stringify(user));
    // default data
    const [score, set_score] = useState(0);
    const [password, set_password] = useState('');
    const [password2, set_password2] = useState('');
    const [old_password, set_old_password] = useState('');
    const [is_loading, set_is_loading] = useState(false);
    const [is_wrong_current_password, set_is_wrong_current_password] = useState(false);
    const [password_changed, set_password_changed] = useState(false);

    // send credentials
    const handle_submit = (evt) =>
    {
        evt.stopPropagation();
        evt.preventDefault();
        const send_data = async () =>
        {
            set_is_loading(true);
            const response = await AUTH.reset_onw_password(user.username, old_password, password);
            if (response.ok)
            {
                set_password('');
                set_password2('');
                set_old_password('');
                set_password_changed(true);
            }
            else
            {
                if (response.status === 403)
                {
                    set_is_wrong_current_password(true);
                } else
                {
                    NotificationManager.error('An error occurred. Please try again later', 'OOPS!', 5000, () => { })
                }
            }
            set_is_loading(false);
        }

        // call the function
        send_data().catch(console.error);
    };

    const handle_password_score = (score) =>
    {
        set_score(score);
    };

    const handle_old_password_change = (value) =>
    {
        set_old_password(value);
        set_is_wrong_current_password(false);
    };


    const classess = is_loading ? 'loading' : '';
    const pass_meter_classess =  ASCIIValid(password) ? 'pass_meter' : 'invisible';

    return (
        <div id="content" className={classess}>
            <AjaxSpinner is_loading={is_loading} />
            <h2>Change Password</h2>

            <form className=''>

                <div className='fields-wrapper'>
                    <div className="field">
                        <label htmlFor='form-password'>Your Current Password</label>
                        <ShowAndHidePassword id="form-password"
                            autohide="true"
                            autoComplete="current-password"
                            required
                            value={old_password}
                            onChange={(e) => handle_old_password_change(e.target.value)}
                        />
                        <OldPassChecker is_wrong={is_wrong_current_password} />
                    </div>
                    <div className="field"></div>
                </div>

                <p>Passwords must contain at least 6 characters and rank as <span style={{ color: "#f6b44d" }}>okay</span>. Use a combination of characters, number and symbols for higher ranking </p>

                <div className='fields-wrapper'>
                    <div className="field">
                        <label htmlFor='form-new-password'>New Password</label>
                        <ShowAndHidePassword id="new-password"
                            autohide="true"
                            // autoComplete="new-password"
                            required
                            value={password}
                            onChange={(e) => set_password(e.target.value)}
                        />
                        <PasswordStrengthBar
                            className={pass_meter_classess}
                            onChangeScore={handle_password_score}
                            password={password}
                            minLength="6"
                        />
                        <ValidPassChecker password={password} />
                    </div>

                    <div className="field">
                        <label htmlFor='form-repeat-password'>Repeat Password</label>
                        <ShowAndHidePassword id="form-repeat-password"
                            autohide="true"
                            // autoComplete="new-password"
                            required
                            value={password2}
                            onChange={(e) => set_password2(e.target.value)}
                        />
                        <SamePassChecker password={password} password_check={password2} />
                    </div>
                </div>

                <div className='buttons'>
                    <ButtonSender
                        on_click={handle_submit}
                        password={password}
                        password_check={password2}
                        old_password={old_password}
                        score={score}
                    />
                </div>

            </form>

            {/* MODAL PASSWORD CHANGED */}
            <PureModal
                isOpen={password_changed}
                closeButton="OK"
                width="80%"
                closeButtonPosition="bottom"
                className="modal-password-changed"
                onClose={() =>
                {
                    set_password_changed(false);
                    return true;
                }}
            >
                <div className="modal-message">
                    <div>
                        <strong>Password changed!</strong>
                        <br />
                        Your password has been successfully changed.
                    </div>
                </div>
            </PureModal>


        </div>
    );
};
