import React from 'react';
import { Link } from "react-router-dom";
import conf from 'conf/config';

export default function NotFound()
{
    return (
        <div className='ajax_error'>
            <h3>404 - Not Found!</h3>
            <div><strong>Oops</strong>&nbsp;the page you are looking for doesn't seem to exist.</div>
            <Link to={conf.route.main}>Go Home</Link>
        </div>
    );
};