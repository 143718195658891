// react core
import React from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
// tagetik ui componetns
import ScrollToTop from 'components/commons/scroll_top';
import BodyClasser from 'components/commons/body_classer';
import SideNav from 'components/commons/side_nav';
// confs
import conf from 'conf/config';



export default function SiteRoot(props)
{
    let location = useLocation();
    const path = location.pathname.replace(conf.route.base + '/', '');
    const classess = `main ${path}`;
    /*
    const history = useLocation();
    const navigate = useNavigate();
    */
    // then I initialize the components with properties

    if(path === conf.route.base || path === '') return <Navigate to={conf.route.my_profile} replace={true} />
    return (
        <>
            <BodyClasser className={path} />
            <ScrollToTop />
            <div className={classess}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title alt">
                            <h2>
                                <a href={conf.route.base}>
                                    <i className="fa-solid fa-address-card"></i>
                                    My Account
                                </a>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-9 pull-right">
                        <Outlet />
                    </div>
                    <div className="col-md-3 pull-left">
                        <SideNav />
                    </div>
                </div>
            </div>
        </>
    );
};

