// I'm not convinced but I decided to use the default component properties approach, see here for further explanation:
// https://stackoverflow.com/questions/47774695/react-functional-component-default-props-vs-default-parameters
// the important thing is that the default state is left empty because an empty object or a null are still something different.
// i.e. in the parent component the state must be like this:
// const [data, set_data] = useState();
// and not like this
// const [data, set_data] = useState({});

import React from 'react';

export default function CompanyCard({ is_manager, company })
{
    return (
        <>
            <dl className="company-card">
                <div>
                    <dt>Company Name</dt>
                    <dd><span>{company.company}</span></dd>
                </div>

                {company.customer_managers?.length > 0 && (
                    <div>
                        <dt>Company Admins</dt>
                        <dd>
                            <ul>
                                {company.customer_managers.map((item, idx) => (
                                    <li key={"manager-" + idx}>
                                        <span>
                                            <a href={'mailto:' + item.email}>
                                            <i className='fa-solid fa-envelope'></i> {item.fullname} </a></span>
                                    </li>
                                ))}
                            </ul>
                        </dd>
                    </div>
                )}

                <div>
                    <dt>Industry</dt>
                    <dd><span>{company.industry}</span></dd>
                </div>

                <div>
                    <dt>Business Sector</dt>
                    <dd><span>{company.business}</span></dd>
                </div>

                <div>
                    <dt>Country</dt>
                    <dd><span>{company.country}</span></dd>
                </div>

                <div>
                    <dt>Domains</dt>
                    <dd>
                        <ul>
                            {company.domains.map((item, idx) => (
                                <li key={"domain-" + idx}><span>@{item}</span></li>
                            ))}
                        </ul>
                    </dd>
                </div>

                <div>
                    <dt>Company Code</dt>
                    <dd><span>{company.code}</span></dd>
                </div>

                {is_manager && (
                <div>
                    <dt>Active Users / Max Users</dt>
                    <dd>
                        <span>{company.active_users} / {company.quota}</span>
                    </dd>
                </div>
                )}

            </dl>
        </>
    );
}

CompanyCard.defaultProps =
{
    company:
    {
        industry: '',
        business: '',
        country: '',
        company: '',
        active_users: '',
        quota: '',
        code: '',
        domains: []
    }
}