// react core
import React from 'react';
import { useState, useEffect } from 'react';
import { my_profile } from 'api/account';
import { NotificationManager } from 'react-notifications';
import { text_labels, countries, profile_select } from 'api/static_data';
import parse from 'html-react-parser';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';

// conf
import _conf from 'conf/config';

// app componets
import AjaxSpinner from 'components/commons/ajax_spinner';

function LabelWithCheck({ value, children, type = 'text', className = '', htmlFor = ''})
{
    let classess = className || '';
    // validation
    if (type === 'text')
    {
        if (value.length < 1) classess += ' required';
    }
    if (type === 'checkbox')
    {
        if (value === false) classess += ' required';
    }
    return (<label className={classess} htmlFor={htmlFor}>{children}</label>)
}

function PrivacyBox({ is_needed, onPrivacyChange })
{
    // see https://stackoverflow.com/questions/56247433/how-to-use-setstate-callback-on-react-hooks
    const [privacy1, set_privacy1] = useState(false);
    const [privacy2, set_privacy2] = useState(false);

    const hndl_privacy1 = () =>
    {
        set_privacy1(!privacy1);
    };

    const hndl_privacy2 = () =>
    {
        set_privacy2(!privacy2);
    };

    useEffect(() =>
    {
        onPrivacyChange(privacy1 && privacy2);
    }, [privacy1, privacy2]);

    if (!is_needed) return <></>;
    return (
        <>
            <div className="field">
                <strong>IMPORTANT:</strong>
                <p>{text_labels.IMPORTANT}</p>
            </div>
            <div className="field field-checkbox">
                <input type="checkbox" checked={privacy1} onChange={hndl_privacy1} id="field-privacy1" />
                <div>
                <LabelWithCheck value={privacy1} type="checkbox" htmlFor="field-privacy1">
                    {parse(text_labels.IHAVEREAD_LBL)}
                </LabelWithCheck>
                <p>{text_labels.IHAVEREAD_DSC}</p>
                </div>
            </div>
            <div className="field field-checkbox">
                <input type="checkbox" checked={privacy2} onChange={hndl_privacy2} id="field-privacy2" />
                <LabelWithCheck value={privacy2} type="checkbox" htmlFor="field-privacy2">
                    {text_labels.EXPRESS}
                </LabelWithCheck>
            </div>
        </>
    );
}

export default function MyProfilePage()
{
    // default data
    const [data, set_data] = useState(
        {
            name: '',
            surname: '',
            company: '',
            profile: '',
            telephone: '',
            country: '',
            job: '',
            // active: false,
            accept_notifications: false,
            privacy_confirmed: false
        });

    const [is_valid, set_is_valid] = useState(false);
    const [is_active, set_is_active] = useState(false);
    const [is_inactive_reason, set_is_inactive_reason] = useState(undefined);
    const [is_profile_visible, set_is_profile_visible] = useState(false);
    // const [is_first_submission, set_first_submission] = useState(false);
    const [modal_complete_profile, set_modal_complete_profile] = useState(false);
    const [modal_first_submission, set_modal_first_submission] = useState(false);
    const [modal_updating_data, set_modal_updating_data] = useState(false);
    const [modal_profile_saved, set_modal_profile_saved] = useState(false);
    const [modal_acknowledgment, set_modal_acknowledgment] = useState(false);

    let countries_options = [];
    let profile_options = [];

    for (const [key, value] of Object.entries(profile_select))
    {
        profile_options.push(
            {
                label: value,
                value: key
            });
    }


    for (const [key, value] of Object.entries(countries))
    {
        countries_options.push(
            {
                label: value,
                value: key
            });
    }

    const acknowledgment_post = (evt) =>
    {

    };

    // select components
    const Select = ({ label, value, options, onChange, label_className, id='' }) =>
    {
        let extraclassess = label_className
        if (value === '') extraclassess += ' required'
        return (
            <>
                <label className={extraclassess} htmlFor={id}>{label}</label>
                <select id={id} value={value} onChange={onChange}>
                    {
                        options.map((option) =>
                        (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))
                    }
                </select>
            </>
        );
    };

    const post_data_is_valid = (obj) =>
    {
        let _is_valid = true;
        // now all fields are required
        let required_fields = ['country', 'company', 'fullname',
            'job', 'name', 'surname', 'telephone'];
        if(!is_valid) required_fields.push('privacy_confirmed');
        if(is_profile_visible) required_fields.push('profile');
        for (let i = 0; i < required_fields.length; i++)
        {
            const cur = required_fields[i];
            if ((obj[cur] === '') || (obj[cur] === false))
            {
                _is_valid = false;
            }
        }
        return _is_valid;
    }

    const handle_save = (evt) =>
    {
        evt.stopPropagation();
        evt.preventDefault();
        const post_data = async () =>
        {
            // already valid remove privacy_confirmed
            if(is_valid) delete data.privacy_confirmed;
            const response = await my_profile.set(data);
            if (response.ok)
            {
                const res_data = await response.json();
                //console.log("DATA is => " + JSON.stringify(res_data));
                set_is_loading(false);
                if (res_data.status === 'ok')
                {
                    set_modal_updating_data(true);
                    setTimeout(() =>
                    {
                        set_modal_updating_data(false);
                        if(is_valid === false)
                        {
                            set_error(false);
                            set_modal_first_submission(true);
                        }
                        else
                        {
                            set_error(false);
                            set_modal_profile_saved(true);
                        }

                    }, 7000);
                }
                else
                {
                    set_error(true);
                }
            }
            else
            {
                set_is_loading(false);
                set_error(true);
            }
        }
        if (!post_data_is_valid(data))
        {
            NotificationManager.error('Please complete your profile with all fields', 'Error');
        }
        else
        {
            set_is_loading(true);
            post_data().catch(console.error);
        }
    };


    // only for debug
    window.$D = data;

    const [is_loading, set_is_loading] = useState(false);
    const [error, set_error] = useState(false);

    // handles
    const set_field = (field, value, evt = null) =>
    {
        // checkbox
        if (evt !== null && evt.type && evt.type === 'change')
        {
            const prev = data[field];
            set_data({ ...data, [field]: !prev });
        }
        // text input
        else
        {
            set_data({ ...data, [field]: value });
        }
        window.$E = evt;
    };

    useEffect(() =>
    {
        let mounted = true;
        const fetchData = async () =>
        {
            const response = await my_profile.get();
            if (response.ok)
            {
                const data = await response.json();
                window.$DATA = data;
                if (mounted)
                {
                    set_is_loading(false);
                    if (data.status === 'ok')
                    {
                        // check null
                        for (let i = 0; i < Object.keys(data.data).length; i++)
                        {
                            const cur = Object.keys(data.data)[i];
                            if (data.data[cur] === null)
                            {
                                data.data[cur] = '';
                            }
                        }
                        set_is_valid(data.is_valid);
                        set_is_active(data.is_active);
                        set_is_inactive_reason(data.is_inactive_reason);
                        set_modal_complete_profile(data.is_valid !== true);
                        set_is_profile_visible(data.profile_visible);
                        set_data(data.data);
                        //set_modal_acknowledgment(!data.acknowledgment);
                        // xx debug
                        console.log("data =>" + JSON.stringify(data));
                    }
                    else
                    {
                        set_error(true);
                    }
                }
            }
            else
            {
                set_is_loading(false);
                set_error(true);
            }
        }
        set_is_loading(true);
        fetchData().catch(console.error);
        return () => mounted = false; // cleanup function
    }, []);


    const classess = is_loading ? 'loading' : '';

    if (error) return <div id="content"><h2>OOPS... An error occurred. Please try again later</h2></div>
    else return (
        <div id="content" className={classess}>
            <AjaxSpinner is_loading={is_loading} />
            {is_valid && <h2>Update My Profile</h2>}

            {is_valid && !is_active && (
                <div className={"profile-message profile-message--" + is_inactive_reason}>
                    <div>
                        <p>You can't access the Community</p>
                        {is_inactive_reason === "quota_reached" && (
                            <p>Your company reached the maximum number of users allowed.</p>
                        )}
                        {is_inactive_reason === "contract_expired" && (
                            <p>Your company contract is exipired</p>
                        )}
                        {is_inactive_reason === "account_deactivated" && (
                            <p>Your account has been deactivated</p>
                        )}
                        <p>
                            Please contact{" "}
                            <a href="mailto:tgk-dl-community@wolterskluwer.com">tgk-dl-community@wolterskluwer.com</a>{" "}
                            for assistance
                        </p>
                    </div>
                </div>
            )}

            {!is_valid && (
                <>
                    <h2>Complete My Profile</h2>
                    <p>
                        Please fill in the form below, all fields are mandatory to access the CCH<sup>&reg;</sup>{" "}
                        Tagetik Community.
                    </p>
                </>
            )}

            <form>
                <div className="field">
                    <LabelWithCheck value={data.name} htmlFor="field-name">
                        First Name:
                    </LabelWithCheck>
                    <input
                        id="field-name"
                        type="text"
                        value={data.name}
                        onChange={(e) => set_field("name", e.target.value)}
                    />
                </div>

                <div className="field">
                    <LabelWithCheck value={data.surname} htmlFor="field-surname">
                        Last Name:
                    </LabelWithCheck>
                    <input
                        id="field-surname"
                        type="text"
                        value={data.surname}
                        onChange={(e) => set_field("surname", e.target.value)}
                    />
                </div>

                <div className="field">
                    <LabelWithCheck value={data.company} htmlFor="field-company">
                        Company:
                    </LabelWithCheck>
                    <input
                        id="field-company"
                        type="text"
                        value={data.company}
                        onChange={(e) => set_field("company", e.target.value)}
                    />
                </div>

                <div className="field">
                    <LabelWithCheck value={data.job} htmlFor="field-job">
                        Job function:
                    </LabelWithCheck>
                    <input
                        id="field-job"
                        type="text"
                        value={data.job}
                        onChange={(e) => set_field("job", e.target.value)}
                    />
                </div>

                {is_profile_visible && (
                    <div className="field">
                        <Select
                            id="field-profile"
                            label="Profile"
                            options={profile_options}
                            value={data.profile}
                            onChange={(e) => set_field("profile", e.target.value)}
                        />
                    </div>
                )}

                <div className="field">
                    <Select
                        id="field-country"
                        label="Country:"
                        options={countries_options}
                        value={data.country}
                        onChange={(e) => set_field("country", e.target.value)}
                    />
                </div>

                <div className="field field-checkbox">
                    <input
                        id="field-accept_notifications"
                        type="checkbox"
                        checked={data.accept_notifications}
                        onChange={(e) => set_field("accept_notifications", e.target.value, e)}
                    />
                    <label type="checkbox" htmlFor="field-accept_notifications">
                        {text_labels.WISH}
                    </label>
                </div>

                <PrivacyBox is_needed={!is_valid} onPrivacyChange={(val) => set_field("privacy_confirmed", val)} />

                <div className="buttons">
                    <button className="button button-primary" onClick={handle_save}>
                        Save
                    </button>
                </div>
            </form>

            {/* <button onClick={() => set_modal_first_submission(true)}>SHOW FIRST SUBMISSION MODAL</button>
                <button onClick={() => set_modal_updating_data(true)}>SHOW UPDATING MODAL</button>
                <button onClick={() => set_modal_profile_saved(true)}>SHOW SAVED MODAL</button> */}

            {/* MODAL COMPLETE YOUR PROFILE */}
            <PureModal
                isOpen={modal_complete_profile}
                closeButton="OK"
                width="80%"
                closeButtonPosition="bottom"
                className="modal-complete-profile"
                onClose={() => {
                    set_modal_complete_profile(false);
                    return true;
                }}
            >
                <div className="modal-message">
                    <div>
                        <strong>Welcome! You are now logged in.</strong>
                        <br />
                        Please complete your profile to join the CCH<sup>&reg;</sup> Tagetik Community.
                    </div>
                </div>
            </PureModal>

            {/* MODAL FIRST SUBMISSION */}
            <PureModal
                isOpen={modal_first_submission}
                closeButton="OK, I want to start"
                width="80%"
                closeButtonPosition="bottom"
                className="modal-first-submission"
                onClose={() => {
                    window.location.href = _conf.alert_base_page;
                }}
            >
                <div className="modal-message">
                    <div>
                        <strong>Changes saved.</strong>
                        <br />
                        You can now start your CCH<sup>&reg;</sup> Tagetik Community experience!
                    </div>
                </div>
            </PureModal>

            {/* MODAL UPDATING DATA */}
            <PureModal
                isOpen={modal_updating_data}
                width="80%"
                className="modal-updating-data"
                closeButtonPosition="bottom"
            >
                <div>
                    <AjaxSpinner is_loading={true} />
                    <div>
                        <strong>Please wait a few seconds</strong>
                        <br />
                        We are updating your data on our servers.
                    </div>
                </div>
            </PureModal>

            {/* MODAL PROFILE SAVED */}
            <PureModal
                isOpen={modal_profile_saved}
                closeButton="OK"
                width="80%"
                closeButtonPosition="bottom"
                className="modal-profile-saved"
                onClose={() => {
                    set_modal_profile_saved(false);
                    return true;
                }}
            >
                <div className="modal-message">
                    <div>
                        <strong>Changes saved.</strong>
                        <br />
                        Thank you for keeping your profile updated.
                    </div>
                </div>
            </PureModal>

            {/* MODAL acknowledgment */}
            <PureModal
                isOpen={modal_acknowledgment}
                closeButton="OK"
                width="80%"
                closeButtonPosition="bottom"
                className="modal-profile-saved"
                onClose={() => {
                    set_modal_acknowledgment(false);
                    return true;
                }}
            >
                <div className="modal-message">
                    <div>
                        <strong>Read carefully.</strong>
                        <br />
                        Privacy Text?
                        <br/>
                        <button className="button button-primary" onClick={acknowledgment_post}>
                        I have read.
                        </button>
                    </div>
                </div>
            </PureModal>
        </div>
    );
};
