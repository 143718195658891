// libs
import { NotificationContainer } from 'react-notifications';
import React, { useState, useEffect } from 'react';

// components
import AjaxSpinner from 'components/commons/ajax_spinner';
import ServerError from 'components/commons/server_error';
import Main from 'components/main'
// styles
import 'react-notifications/lib/notifications.css';
// import 'css/accounts.css';
import 'scss/accounts.scss';
// conf
import conf from 'conf/config'
// api
import { AUTH } from 'api/account';

// debug for dev
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
{
    window.$DEBUG = true;
    window.$CONF = conf;
    window.konsole = {log: console.log };
}
else
{
    window.konsole = { log : (msg) => {} }
}

function _html(raw, element)
{;
    const parser = new DOMParser();
    let doc = parser.parseFromString(raw, 'text/html');
    let inner_html = doc.body.getElementsByTagName(element)[0].innerHTML
    return { __html: inner_html };
}

// this dynamic component create header and footer from html fetched data and relative tag
const CreateBrandSectionFromHTML = ({html_data, html_tag}) =>
{
    // const HTMLTag = `${html_tag}`;
    // return <HTMLTag dangerouslySetInnerHTML={_html(html_data, html_tag)} />;
    return <></>
};

function App()
{
    // states
    const [userData, setUserData] = useState(null);
    const [htmlData, setHtmlData] = useState(null);

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            // not sure if need inside here
            //const user_data = await fetch(conf.endpoints.userdata, conf.ajax_configuration_object).then(res => res.json());

            // getting header
            const html_header = await fetch(conf.resources.header).then(res =>
            {
                if (!res.ok)
                {
                    const err = new Error("Not 2xx response");
                    err.response = res;
                    throw err;
                }
                else return res.text()
            }).catch(err => console.log('head error => ' + err));

            // getting footer
            const html_footer = await fetch(conf.resources.footer).then(res =>
            {
                if (!res.ok)
                {
                    const err = new Error("Not 2xx response");
                    err.response = res;
                    throw err;
                }
                else return res.text()
            }).catch(err => console.log('head error => ' + err));

            setHtmlData(
                {
                    header: html_header || '<header><h1>not found</h1></header>',
                    footer: html_footer || '<footer><h1>not found</h1></footer>',
                });
        };
        // call the function
        fetchData().catch(console.error);
    }, []);

    useEffect(() =>
    {
        let mounted = true;
        const fetchData = async () =>
        {
            const response =  await AUTH.info();
            window.$R = response;
            if(response.ok)
            {
                const data = await response.json();
                if (mounted)
                {
                    if(data.ok && data.ok === true)
                    {
                        setUserData(data);
                    }
                    else
                    {
                        let tmp = conf.login_url + '?came_from=' + conf.came_from
                        setTimeout(() =>
                        {
                            window.location = tmp;
                        }, 1000);
                    }
                }
            }
            else
            {
                console.error("server error");
            }
        }
        // call the function
        fetchData()
        // make sure to catch any error
        .catch(console.error);
        return () => mounted = false; // cleanup function
    }, []);
    if(userData === null) return <AjaxSpinner />;
    if (userData !== null && userData.status !== 'logged')
    {
        if (htmlData === null) return <AjaxSpinner />;
        else return (
            <>
                <CreateBrandSectionFromHTML
                    html_data={htmlData.header}
                    html_tag='header'
                />
                <ServerError code={userData.errorcode || 0} />
                <CreateBrandSectionFromHTML
                    html_data={htmlData.footer}
                    html_tag='footer'
                />
            </>
        );
    }
    else
    {
        if (htmlData === null) return <AjaxSpinner />;
        else return (
            <>
                <CreateBrandSectionFromHTML
                    html_data={htmlData.header}
                    html_tag='header'
                />
                <Main user={userData}/>
                <CreateBrandSectionFromHTML
                    html_data={htmlData.footer}
                    html_tag='footer'
                />
                <NotificationContainer/>
            </>
        );
    }
}
export default App;

