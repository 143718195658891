import React from 'react';
import {  NavLink } from "react-router-dom";
import conf from 'conf/config';
import md5 from 'md5';

const _nav_menu_data =
[
    {
        route: conf.route.my_profile,
        label: 'My profile',
        icon: 'fa fa-user'
    },
    {
        route: conf.route.my_company,
        label: 'My company',
        icon: 'fa fa-building'
    },
    {
        route: conf.route.change_password,
        label: 'Change password',
        icon: 'fa-solid fa-key'
    },
    {
        route: conf.route.my_notifications,
        label: 'Community updates',
        icon: 'ui-icon-notifications-bell'
    },
];

const nav_menu_data = _nav_menu_data.map(item =>
    {
        return {
            route: `${conf.route.base}/${item.route}`,
            label: item.label,
            id: md5(item.label),
            icon: item.icon
        }
    });

export default function SideNav()
{
    return (
        <div id="sidenav">
            <ul className="vertical-nav">
                {
                    nav_menu_data.map(item =>
                        <li key={item.id}>
                            <NavLink to={item.route}>
                                <i className={item.icon}></i> <span>{item.label}</span>
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};