import conf from 'conf/config';

const my_profile = 
{
    get : async () =>
    {
        return fetch(conf.endpoints.get_user_profile, AJAX_CONF_FACTORY('GET'));
    },
    set: async (obj) =>
    {
        console.log("post obj " + JSON.stringify(obj));
        return fetch(conf.endpoints.set_user_profile, AJAX_CONF_FACTORY('POST', obj));
    }
};

const change_password =
{
    set: async (obj) =>
    {
        return new Promise((resolve, reject) => setTimeout(reject({status:'error', msg:'not yet implemented'}), 1000));
    }
};

const notifications =
{
    get_all: async () =>
    {
        return fetch(conf.endpoints.get_my_alerts, AJAX_CONF_FACTORY('GET'));
    },
    see: async (uid) =>
    {
        return fetch(conf.endpoints.see_my_alert, AJAX_CONF_FACTORY('POST',
        {
            uid: decodeURIComponent(uid)
        }));
    }
};

const company =
{
    get: async () =>
    {
        return fetch(conf.endpoints.customer_profile, AJAX_CONF_FACTORY('GET'));
    }
};

const AUTH =
{
    info: async () =>
    {
        return fetch(conf.endpoints.info, AJAX_CONF_FACTORY('POST'));
    },
    reset_onw_password: async (user_id, old_password, new_password) =>
    {
        const tmp = conf.endpoints.lost_password.replace('{USERID}', user_id)
        return fetch(tmp, AJAX_CONF_FACTORY('POST',
        {
            old_password: old_password,
            new_password: new_password
        }));
    },
};

const user_profile =
{
    activate: async (user_id) =>
    {
        return fetch(conf.endpoints.activate_user, AJAX_CONF_FACTORY('POST',
        {
            email: user_id
        }));
    },
    deactivate: async (user_id) =>
    {
        return fetch(conf.endpoints.deactivate_user, AJAX_CONF_FACTORY('POST',
        {
            email: user_id
        }));
    },
};

const AJAX_CONF_FACTORY = (_method='GET', body=null) =>
{
    const METHODS = ['GET', 'POST', 'HEAD', 'DELETE'];
    const method = _method.toUpperCase();
    let json_body;
    let tmp = {
        method: method,
        cache: 'no-cache',
        credentials: "include",
        headers:
        {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    if(!METHODS.includes(method))
    {
        console.error('invalid method');
        return null;
    }
    if(body !== null)
    {
        try
        {
            json_body = JSON.stringify(body);
            tmp.body = json_body
        }
        catch (error)
        {
            console.error(error);
            json_body = null;
            tmp = null
        }
    }
    return tmp;
}

export
{
    AJAX_CONF_FACTORY,
    AUTH,
    company,
    notifications,
    change_password,
    my_profile,
    user_profile
};
