// react core
import React from 'react';
import { useState, useEffect } from 'react';
import { notifications } from 'api/account';

// app componets
import AjaxSpinner from 'components/commons/ajax_spinner';

// conf
import _conf from 'conf/config';

// widget notification
function Notification({date, title, text, path, seen, uid})
{
    const url = `${_conf.route.seen_and_redirect}?uid=${encodeURIComponent(uid)}&to=${encodeURIComponent(path)}`;
    const base_classess = 'notification-item';
    const cur_classess = seen === true ? base_classess + ' notification-seen': base_classess;
    return (
        <a href={url} className={cur_classess}>
                <div className='notification-date'>{date}</div>
                <div className='notification-title'>{title}</div>
                <div className='notification-text'>{text}</div>
        </a>
    )
}

export default function NotificationPage()
{

    const [data, set_data] = useState([]);
    const [error, set_error] = useState(false);
    const [is_loading, set_is_loading] = useState(false);

    useEffect(() =>
    {
        let mounted = true;
        const fetchData = async () =>
        {
            const response =  await notifications.get_all();
            if(response.ok)
            {
                const data = await response.json();
                if (mounted)
                {
                    set_is_loading(false);
                    if(data.status === 'success' && Array.isArray(data.alerts))
                    {
                        const alerts = data.alerts;
                        alerts.length = Math.min(alerts.length, 20)
                        set_data(alerts);
                    }
                    else
                    {
                        set_data([]);
                        set_error(true);
                    }
                }
            }
            else
            {
                set_error(true);
                set_is_loading(false);
            }
        }
        // call the function
        set_is_loading(true);
        fetchData().catch(console.error);
        return () => mounted = false; // cleanup function
    }, []);

    const classess = is_loading ? 'loading' : '';
    if(error === true) return (
        <div id="content" className='errors'>
            Unable to reach server
        </div>
    );
    return (
        <div id="content" className={classess}>
            <AjaxSpinner is_loading={is_loading} />
            <h2>Community Updates</h2>
            <div className='notifications-container'>
            {
                data.map(item =>
                    <Notification
                        key={item.uid}
                        title={item.title}
                        path={item.path}
                        date={item.date}
                        text={item.text}
                        seen={item.seen}
                        uid={item.uid}
                    />
                )
            }
            </div>
        </div>
    );
}
