// from react core
import React from 'react';
import { useState, useEffect } from 'react';

// app componets
import AjaxSpinner from 'components/commons/ajax_spinner';

// api
import { notifications } from 'api/account';

// conf
import _conf from 'conf/config';

export default function SendEndRedirect({ user })
{
    const [to, set_to] = useState(null);
    const [is_loading, set_is_loading] = useState(false);
    const [error, set_error] = useState(false);

    useEffect(() =>
    {
        const tmp = new URLSearchParams(window.location.search);
        set_to(tmp.get('to'));
    }, []);

    useEffect(() =>
    {
        const tmp = new URLSearchParams(window.location.search);
        const uid = tmp.get('uid');
        const to = tmp.get('to');
        let mounted = true;
        const fetchData = async () =>
        {
            const response =  await notifications.see(uid)
            if(response.ok)
            {
                const data = await response.json();
                // XXX missing check data
                // 
                if (mounted)
                {
                    set_is_loading(false);
                    const target_url = `${_conf.alert_base_page}${decodeURIComponent(to)}`;
                    setTimeout(() =>
                    {
                        window.location.href = target_url;
                    }, 250);
                }
            }
            else
            {
                set_is_loading(false);
                set_error(true);
            }
        }
        set_is_loading(true);
        fetchData().catch(console.error);
        return () => mounted = false; // cleanup function
    }, []);

    const classess = is_loading ? 'loading' : '';
    if(error === true) return (
        <div id="content" className='errors'>
            Unable to reach server
        </div>
    );
    const str_to = decodeURIComponent(to);
    return (
        <div id="content" className={classess}>
            <AjaxSpinner is_loading={is_loading} />
            <div className="redirect">redirecting to...</div>
            <div>
                {`${_conf.alert_base_page}${str_to}`}
            </div>
        </div>
    );
};