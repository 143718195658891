import React from 'react';

export default function ServerError({code})
{
    const my_style =
    {
        padding: '30px'
    };
    return (
        <main className="container">
            <div className="row">
                <div style={my_style}>
                    <h3>Error code: {code}</h3>
                    <div>
                        <strong>Oops</strong>&nbsp;there seems to be some problem with the server, please contact support.
                    </div>
                </div>
            </div>
        </main>
    );
};